.footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  z-index: 10;
}

.footer-content {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-content-body {
  width: 60%;
  display: flex;
  justify-content: space-around;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1300px) {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 1300px) {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }
}

.footer-content-body-copyright {
  height: 64px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-left: 10px;
  @media (max-width: 1300px) { 
      display: flex !important;
      margin-left: 0;
      
  }
}
