
.header {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  z-index: 10;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
}

.header-navigation {
  @media (max-width: 1300px) {
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;
    flex-direction: column;
  }
}

.header-navigation span {
  color: #aaa;
  font-size: 15px;
  line-height: 48px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 18px;
}

.header-navigation span:hover {
  color: #89d625;
}

.custom-span {
  margin-left: 40px;
  cursor: pointer;
  @media (max-width: 1300px) {
    margin-left: 0 !important;
  }
}

.ant-dropdown-link {
  font-size: 18px !important;
  @media (max-width: 1300px) {
    margin-left: 32px !important;
  }
}
