

.faq {
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.faq-panel {
    color: #aaa;
    background: -webkit-linear-gradient(45deg, #1f1f1f,#0a0b0b);/* Chrome 10-25, Safari 5.1-6 */                          
    background: linear-gradient(45deg, #1f1f1f,#0a0b0b);/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */                                               
}

.faq p {
    color: #aaa;

}

.ant-collapse-header {
    color: #aaa !important;
    font-size: 24px !important;
    display: flex;
    justify-content: center;
}

.ant-collapse-content-box {
    background-color: #101010 !important;
    font-size: 24px !important;
    display: flex;
    justify-content: center;
}

.faq-content {
    height: calc(100% - 51px);
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1300px) {
      width: 100% !important;
     }
}