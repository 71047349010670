@font-face {
  font-family: "KaloChamBrush";
  src: local("KaloChamBrush"),
    url("./media/fonts/KarloCham-Brush.otf") format("truetype");
}

@font-face {
  font-family: "KaloChamLine";
  src: local("KaloChamLine"),
    url("./media/fonts/KarloCham-Line.otf") format("truetype");
}

:root {
  --scrollbarBgColor: rgba(0, 0, 0, 0.05);
  --scrollbarThumbColor: rgba(0, 0, 0, 0.15);
  --scrollbarThumbColorSecond: rgba(255, 255, 255, 0.7);
  --scrollbarWidth: 16px;
  --scrollbarBorder: 0 solid var(--scrollbarBgColor);
  --scrollbarBorderRadius: calc(var(--scrollbarWidth) / 4);
}

.body::-webkit-scrollbar {
  width: var(--scrollbarWidth);
}
.body::-webkit-scrollbar-track {
  background: var(--scrollbarBgColor);
  border-radius: var(--scrollbarBorderRadius);
}
.body::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumbColor);
  border-radius: var(--scrollbarBorderRadius);
  border: var(--scrollbarBorder);
  background-image: linear-gradient(
    180deg,
    var(--scrollbarThumbColor) 0%,
    var(--scrollbarThumbColorSecond) 90%
  );
}

* {
  color: #aaa;
  font-family: "KaloChamLine";
}

.body {
  height: calc(100vh);
  width: calc(100vw);
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1%;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: var(--scrollbarWidth);
  scrollbar-color: var(--scrollbarThumbColor) var(--scrollbarBgColor);
  background: -webkit-linear-gradient(
    45deg,
    #1f1f1f,
    #0a0b0b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    45deg,
    #1f1f1f,
    #0a0b0b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  @media (max-width: 1300px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.body-divider {
  display: none;

  @media (max-width: 1300px) {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    width: 80%;
  }
}

.body-content {
  padding-left: 10%;
  padding-right: 10%;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: calc(100% - 0px);
  @media (max-width: 1300px) {
    padding: 0 !important;
    height: unset;
  }
}
