.container {
    width: 100%;
    height: calc(100%);
    //padding-left: 10px;
    //padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  h2 {
    font-size: 26px;
    margin: 20px 0;
    text-align: center;
    small {
      font-size: 0.5em;
    }
  }
  
  .responsive-table {
      width: 100%;
      height: calc(100% - 51px);
      padding: 0 !important;
    li {
      border-radius: 3px;
      padding: 25px 30px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }
    .table-header {
      background-color: #95A5A6;
      font-size: 20px;
      
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
    .col {
        color: #89d625;
    }
    .table-row {
      background-color: #1f1f1f;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
      font-size: 20px;
    }
    .col-1 {
      flex-basis: 10%;
    }
    .col-2 {
      flex-basis: 40%;
    }
    .col-3 {
      flex-basis: 25%;
    }
    .col-4 {
      flex-basis: 25%;
    }
    
    @media all and (max-width: 1300px) {
      .table-header {
        display: none;
      }
      .table-row{
        font-size: 14px; 
      }
      li {
        display: block;
      }
      .col {
        
        flex-basis: 100%;
        
      }
      .col {
        display: flex;
        &:before {
          color: #6C7A89;
          padding-right: 10px;
          content: attr(data-label);
          flex-basis: 50%;
          text-align: right;
        }
      }
    }
  }