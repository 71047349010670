.about {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.about-content {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  overflow: none;
}

.about-content p {
  font-size: 24px;
  text-align: center;
  z-index: 10;
  background: none;
}

