.road-progress-span {
  align-items: center;
  border: 2px solid #988036;
  border-radius: 50%;
  color: #988036;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  font-size: 19px;
  font-weight: 700;
  height: 62px;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  width: 62px;
}

.roadmap {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  
}

.roadmap-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 51px);

}

.roadmap-grid {
  width: 100%;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  height: 33%;
  justify-content: space-between;
  @media (max-width: 1300px) {
      justify-content: center;
      flex-direction: column;
      height: 100%;
  }
}

.swiper-content {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 33%;
  border: 3px solid #e9e4e0;
  border-radius: 0.3em;
  @media (max-width: 1300px) {
      width: 100%;
      height: unset;
  }
  
}
.progr {
  @media (max-width: 1300px) {
    display: none;
}
}

.progr-mob {
  display: none;
  @media (max-width: 1300px) {
    display: flex;
    justify-content: center;
    align-items: center;

}
}

.ant-progress-text {
  color: #89d625 !important;
}

.swiper-content-text {
  height: calc(100% - 51px);
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rounded {
  height: calc(100% - 51px);

  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  counter-reset: li;
  list-style: none;
  font: 24px "Trebuchet MS", "Lucida Sans";
  @media (max-width: 1300px) {
    font: 16px "Trebuchet MS", "Lucida Sans";
    width: 70%;
  }
  padding: 0;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.rounded a {
  position: relative;
  display: block;
  padding: 0.4em 0.4em 0.4em 2em;
  margin: 0.5em 0;
  background: #534a41;
  color: rgb(230, 214, 214);
  text-decoration: none;
  border-radius: 0.3em;
  transition: 0.3s ease-out;
}
.rounded a:hover {
  background: #e9e4e0;
}
.rounded a:hover:before {
  transform: rotate(360deg);
}
.rounded a:before {
  content: attr(data-content);
  counter-increment: li;
  position: absolute;
  left: -1.3em;
  top: 50%;
  margin-top: -1.3em;
  background: #534a41;
  height: 2em;
  width: 2em;
  line-height: 2em;
  border: 0.3em solid white;
  text-align: center;
  font-weight: bold;
  border-radius: 2em;
  transition: all 0.3s ease-out;
}
